<template>
  <div class="text-end">
    <!-- APPBAR -->
    <v-app-bar app color="rgb(94, 164, 176)" elevation="0" dark>
      <v-row align="center" dense v-if="permissoes && permissoes.username">
        <v-row class="ma-0 pa-0" align="center">
          <v-col class="ma-0 pa-0" cols="1">
            <v-app-bar-nav-icon v-show="permissoes && permissoes.username" class="ml-1" rounded dark
              @hover="drawer = true" @click="drawer = true" />
          </v-col>
          <v-col class="ma-0 pa-0" cols="1">
            <v-btn plain rounded color="white"
              :class="`mt-4 mb-4 ${permissoes && permissoes.username ? '' : 'mx-auto'}`">
              <v-img alt="HRO Hospital de Referência Oftamológico" class="shrink" src="../../assets/logo.png" width="80"
                @click="go('home')" />
            </v-btn>
          </v-col>
          <!-- <v-col v-show="isHomolog && permissoes && permissoes.permissoes['374']" class="mt-5 ml-10">
            <v-row>
              <v-switch v-model="switchBase" class="mr-1" @change="changeDatabase" />
              <v-icon class="mb-5 mr-1">mdi-database</v-icon>
              {{ switchBase ? "db-prd" : "db-test" }}
            </v-row>
          </v-col> -->
        </v-row>
        <v-col v-if="permissoes && permissoes.username" cols=5 dense>
          <v-avatar @click="go('contas')">
            <v-img v-if="permissoes.avatar && false" :src="permissoes.avatar" :alt="permissoes.first_name"></v-img>
            <v-btn icon dark v-else>
              <v-icon>mdi mdi-account</v-icon>
            </v-btn>
          </v-avatar>
          <Logout v-if="checkScreen()" />
          <!-- <span class="white--text text-end">{{ permissoes.first_name }} {{ permissoes.last_name }} </span> -->
          
        </v-col>
      </v-row>
      <v-btn v-else plain rounded color="white"
        :class="`mt-4 mb-4 ${permissoes && permissoes.username ? '' : 'mx-auto'}`">
        <v-img alt="HRO Hospital de Referência Oftamológico" class="shrink" src="../../assets/logo.png" width="80"
          @click="go('home')" />
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" temporary fixed>
      <v-divider />
      <v-list dense nav>
        <v-list-item @click="go(menu.params)" link v-for="menu in menus" :key="menu.id">
          <v-list-item-icon>
            <v-icon>{{ menu.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-left text-uppercase">{{ menu.text }}</v-list-item-title>
            <v-divider />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import api from '@/http'
import { isHomolog } from '@/http'
export default {
  name: 'Drawer',
  components: {
    Logout: () => import('../Logout.vue')
  },
  data: () => ({
    loading: false,
    initiallyOpen: [''],
    items: [],
    btn: true,
    tree: [],
    drawer: false,
    // permissoes: {},
    switchBase: false,
    isHomolog: isHomolog
  }),
  computed: {
    ...mapGetters(['cor', 'mensagem', 'permissoes']),
    home() {
      return this.$route.name == 'login' || this.$route.name == 'home' || this.$route.name == undefined ? false : true
    },
    breadcrumbs() {
      let path = this.$route.path
      let arr = path.split('/')
      let tamanho = arr.length
      let caminho = []
      if (tamanho > 1) {
        let c = ''
        let t = ''
        for (let i = 0; i < tamanho; i++) {
          c = `/${arr[i]}`
          t = i == 0 ? 'home' : `${arr[i]}`
          caminho.push({ text: t, disabled: (tamanho == i + 1), href: c })
        }
      }
      return caminho

    },
    menus() {
      return this.items
    },
    icons() {
      return []//AppConfigService.icons()
    },
  },
  methods: {
    changeDatabase() {
      console.log(this.switchBase)
      console.log(this.permissoes)
    },
    checkScreen: () => location.pathname !== '/login',
    go(val) {
      if (this.$router.currentRoute.name !== val) {
        this.$router.push({ name: val }).catch(() => { });
      }
    },
    fechaLoading() {
      this.$store.dispatch('btnLoading', false)
      this.$store.dispatch('cor', '')
      this.$store.dispatch('mensagem', '')
    },
    async get_apps() {
      this.loading = true
      api('base/apps/?local=SIDE BAR')
        .then(response => {
          this.items = response.data
        // })
        // .catch(error => {
        //   if (error.response) {
        //     // Request made and server responded
        //     console.log(error.response.data);
        //     console.log(error.response.status);
        //     console.log(error.response.headers);
        //   } else if (error.request) {
        //     // The request was made but no response was received
        //     console.log(error.request);
        //   } else {
        //     // Something happened in setting up the request that triggered an Error
        //     console.log('Error', error.message);
        //   }

        }).finally(() => {
          this.loading = false
        })
    },

    async get_permissoes() {
      this.loading = true
      this.$store.dispatch('permissoes', 'contas/usuarios/permissoes/')
      this.loading = false
      // api('contas/usuarios/permissoes/')
      //   .then(response => {
      //     this.permissoes = response.data[0]
      //   }).catch(error => {
      //     if (error.response) {
      //       // Request made and server responded
      //       console.log(error.response.data);
      //       console.log(error.response.status);
      //       console.log(error.response.headers);
      //     } else if (error.request) {
      //       // The request was made but no response was received
      //       console.log(error.request);
      //     } else {
      //       // Something happened in setting up the request that triggered an Error
      //       console.log('Error', error.message);
      //     }

      // }).finally(() => {
      //   this.loading = false
      // })
    },


  },
  async mounted() {
    if (localStorage.getItem('tokenAccess')) {

      this.get_apps();
      this.get_permissoes();

      // let response = await api('etl/config/')
      // console.log(response.data)
    }
  }
};

</script>